<template>
    <div>
        <!----DIALOGE---->
        <!--<v-layout row justify-center>-->
        <add-person :aktionen="aktionen" :firmen.sync="firmen" :newPersonDialog.sync="newPersonDialog" :laender="laender" :kategorien="kategorien"></add-person>
        <v-dialog v-model="newFirmaDialog" persistent max-width="700">
                <add-firma :firmen.sync="firmen" :dialog.sync="newFirmaDialog" :aktion="aktionen" :laender="laender" :kategorien="kategorien"> </add-firma>
        </v-dialog>
        <add-aktion :dialog.sync="newAktionDialog" v-show="newAktionDialog"></add-aktion>
        <add-praesent :dialog.sync="newPrasentDialog" v-show="newPrasentDialog"></add-praesent>
        <!--</v-layout>-->
        <!-----FILTER---->
        <v-card color="accent" dark class="pl-3 pr-3 pt-3">
          <v-row v-if="active == 0 ||active == 3" >
            <v-col cols="2">
              <v-text-field label="Vorname"
                            filled
                            :disabled="loading"
                            clearable
                            @change="filterPersonen"
                            v-model="filter.vorname"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Nachname"
                            filled
                            :disabled="loading"
                            clearable
                            @change="filterPersonen"
                            v-model="filter.nachname"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Firma"
                            filled
                            :disabled="loading"
                            clearable
                            @change="filterPersonen"
                            v-model="filter.firma"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="E-mail"
                            filled
                            :disabled="loading"
                            clearable
                            @change="filterPersonen"
                            v-model="filter.email"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Ort"
                            filled
                            :disabled="loading"
                            clearable
                            @change="filterPersonen"
                            v-model="filter.ort"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="active == 1">
            <v-col cols="2">
              <v-text-field label="Name"
                            @change="filterFirmaData"
                            filled
                            color="white"
                            v-model="filterfirmen.name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Straße"
                            @change="filterFirmaData"
                            filled
                            color="white"
                            v-model="filterfirmen.straße"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Ort"
                            @change="filterFirmaData"
                            filled
                            color="white"
                            v-model="filterfirmen.ort"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Plz"
                            @change="filterFirmaData"
                            filled
                            color="white"
                            v-model="filterfirmen.plz"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Land"
                            color="white"
                            filled
                            @change="filterFirmaData"
                            v-model="filterfirmen.land"></v-text-field>
            </v-col>
          </v-row>
                  <v-row v-if="active == 2">
                    <v-col cols="2">
                      <v-text-field label="Name"
                                    @change="filterAktionen"
                                    color="white"
                                    filled
                                    v-model="filterAktion.name"></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
        </v-card>
        <!----TABS---->
        <v-progress-linear color="accent" v-show="loading != false" indeterminate></v-progress-linear>
        <v-tabs v-model="active"
                light
                class="mt-1"
                color="accent"
                slider-color="black">
            <v-tab :key="0"
                   @click="filterPersonen"
                   ripple>
                <span>Personen</span>
            </v-tab>
            <v-tab :key="1"
                   ripple>
                <span>Firmen</span>
            </v-tab>
            <v-tab :key="2"
                   ripple>
                <span>Aktionen</span>
            </v-tab>
            <v-tab :key="3"
                   @click="filterPersonen"
                   v-if="profile && profile.isAdmin"
                   ripple>
                <span>Papierkorb</span>
            </v-tab>

            <v-spacer></v-spacer>
          <div>
            <v-btn v-if="active == 0" @click="newPersonDialog = true" large color="secondary" dark >
              <v-icon>mdi-plus</v-icon>
              Person Hinzufügen
            </v-btn>
              <v-btn v-else-if="active == 1" @click="newFirmaDialog = true" dark large color="secondary" >
                <v-icon>mdi-plus</v-icon>
                Firma Hinzufügen
              </v-btn>
              <v-btn v-else-if="active == 2" @click="newAktionDialog = true"  large color="secondary">
                <v-icon>mdi-plus</v-icon>
                Aktion Hinzufügen
              </v-btn>
          </div>

            <v-tab-item :key="0">
                <personen @filter="filterPersonen" :sortPersonen="sortPersonen" :laender="laender" :firmen="firmen" v-show="!loading"></personen>
            </v-tab-item>
            <v-tab-item :key="1">
                <firmen @filter="filterFirmaData" :firmen="filteredFirmen" :laender="laender" :aktionen="aktionen"></firmen>
            </v-tab-item>
            <v-tab-item :key="2">
                <aktionen></aktionen>
            </v-tab-item>
            <v-tab-item  :key="3" v-if="profile && profile.isAdmin">
                <papierkorb @filter="filterPersonen" :laender="laender"></papierkorb>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        data() {
            return {
                selectsItems: { anrede: ['Herr', 'Frau', 'K/A'], kategorie: [], land: [], aktion: [], name: [], quelle: [] },
                activ: false,
                ready: false,
                personenCopy: [],
                kategorien: [],
                aufsteigend: true,
                test: [],
                active: 0,
                laender: [],
                sortPersonen: { anrede: 0, titel: 0, vorname: 0, nachname: 0, email: 0, telefon: 0, ort: 0, firma: 0, kontaktVon: 0 },
                sortIndex: [],

                newPersonDialog: false,
                newAktionDialog: false,
                newFirmaDialog: false,
                newPrasentDialog: false,

                filter: { vorname: '', nachname: '', firma: '', email: '', ort: '' },
                filterfirmen: { name: '', straße: '', ort: '', plz: '', land: '' },
                filterAktion: { name: '' },
                aktionsId: null,
                teilnehmerDialog: false,
                aktionsDialog: false,
                aktionsPersonen: [],
            }

        },
        watch: {

        },
        computed: {
            ...mapState(['aktionen', 'personen', 'filteredPersonen', 'profile', 'loading', 'firmen', 'filteredFirmen', 'papierkorb'])

        },
        created() {
            this.$store.dispatch('getFirmen');
            this.$store.dispatch('getAktionen');
            this.$store.dispatch('getPersonen');
            this.GetLaender();
        },
        mounted() {
        },
        methods: {
            GetLaender: function () {
                this.$http.get('/api/Stammdaten/Laender').then(response => {
                    this.laender = response.data;
                }).catch((error) => console.log(error));
            },
            filterAktionen: function () {
                var data = this.aktionen;

                if (this.filterAktion.name.length > 0) {
                    data = data.filter(p => (p.name != null && p.name.toLowerCase().indexOf(this.filterAktion.name.toLowerCase()) != -1));
                }

                this.$store.dispatch('setfilteredAktionen', { data: data });
            },
            activeFilters: function () {
                var filterString = "";
                for (var i = 0; i < this.sortIndex.length; i++) {

                    if (i == 0) {
                        filterString += this.sortIndex[i];
                    }
                    else if (filterString != null) {
                        filterString += " - " + this.sortIndex[i];
                    }

                }
                return filterString;
            },

            filterPersonen: function () {
                var data = this.active == 0 ? this.personen : this.papierkorb;

                if (this.filter.vorname != null && this.filter.vorname.length > 0) {
                    data = data.filter(p => (p.vorname != null && p.vorname.toLowerCase().indexOf(this.filter.vorname.toLowerCase()) != -1));
                }
                else if (this.filter.nachname != null && this.filter.nachname.length > 0) {
                    data = data.filter(p => (p.nachname != null && p.nachname.toLowerCase().indexOf(this.filter.nachname.toLowerCase()) != -1));
                }
                else if (this.filter.firma != null && this.filter.firma.length > 0) {
                    data = data.filter(p => (p.firma != null && p.firma.toLowerCase().indexOf(this.filter.firma.toLowerCase()) != -1));
                }
                else if (this.filter.email != null && this.filter.email.length > 0) {
                    data = data.filter(p => (p.email != null && p.email.toLowerCase().indexOf(this.filter.email.toLowerCase()) != -1));
                }
                else if (this.filter.ort != null && this.filter.ort.length > 0) {
                    data = data.filter(p => p.ort != null && (p.ort.toLowerCase().indexOf(this.filter.ort.toLowerCase()) != -1));
                }
                else {
                    data = this.active == 0 ? this.personen : this.papierkorb;
                }

                if (this.active == 0) {
                    this.$store.dispatch('setfilteredPersonen', {
                        data: data
                    });
                }
                else {
                    this.$store.dispatch('setfilteredPapierkorb', {
                        data: data
                    });
                }

            },
            sortArray: function (list, prop) {
                //if (prop == "ort" || prop == "firma") {

                //    var tempProp = prop == "firma" ? "name" : prop;
                //    list.sort(function (a, b) {
                //        if (a.firma[tempProp] == null || a.firma[tempProp] == undefined) {
                //            a.firma[tempProp] = "";
                //        }
                //        if (b.firma[tempProp] == null || b.firma[tempProp] == undefined) {
                //            b.firma[tempProp] = "";
                //        }
                //        return a.firma[tempProp].toLowerCase().localeCompare(b.firma[tempProp].toLowerCase());
                //    })
                //}

                list.sort(function (a, b) {
                    if (a[prop] == null || a[prop] == undefined) {
                        a[prop] = "";
                    }
                    if (b[prop] == null || b[prop] == undefined) {
                        b[prop] = "";
                    }

                    return a[prop].toLowerCase().localeCompare(b[prop].toLowerCase());
                })

                if (!this.aufsteigend) {
                    return list.reverse();
                }

                return list;
            },
            getDifferentItems: function (list, prop) {
                var items = [];
                //if (prop == "ort" || prop == "firma") {
                //    var tempProp = prop == "firma" ? "name" : prop;
                //    for (var i = 0; i < list.length; i++) {
                //        if (items.indexOf(list[i].firma[tempProp].toLowerCase()) == -1) {
                //            items.push(list[i].firma[tempProp].toLowerCase());
                //        }

                //    }
                //}

                for (var i = 0; i < list.length; i++) {
                    if (items.indexOf(list[i][prop].toLowerCase()) == -1) {
                        items.push(list[i][prop].toLowerCase());
                    }
                }

                return items;

            },

            filterFirmaData: function () {
                var data = JSON.parse(JSON.stringify(this.firmen));

                if (this.filterfirmen.name.length > 0) {
                    data = data.filter(p => (p.name != null && p.name.toLowerCase().indexOf(this.filterfirmen.name.toLowerCase()) != -1));
                }
                if (this.filterfirmen.straße.length > 0) {
                    data = data.filter(p => (p.straße != null && p.straße.toLowerCase().indexOf(this.filterfirmen.straße.toLowerCase()) != -1));
                }
                if (this.filterfirmen.ort.length > 0) {
                    data = data.filter(p => (p.ort != null && p.ort.toLowerCase().indexOf(this.filterfirmen.ort.toLowerCase()) != -1));
                }
                if (this.filterfirmen.plz.length > 0) {
                    data = data.filter(p => (p.plz != null && p.plz.toLowerCase().indexOf(this.filterfirmen.plz.toLowerCase()) != -1));
                }
                if (this.filterfirmen.land.length > 0) {
                    data = data.filter(p => (p.land != null && p.land.toLowerCase().indexOf(this.filterfirmen.land.toLowerCase()) != -1));
                }
                this.$store.dispatch('setfilteredFirmen', {
                    data: data
                });
            },
        },

    }
</script>
